import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'
import Project from 'components/project'

const Project1Link = <Link to="/project1">Read More</Link>
const Project2Link = <Link to="/project2">Read More</Link>
const Project3Link = <Link to="/project3">Read More</Link>

const Work = () => {
  return (
    <TwoColumns
      wide
      leftColumn={<SectionHeading>Work</SectionHeading>}
      rightColumn={
        <Fragment>
          <Project
            logo=""
            title="Project 1"
            abstract="This is the description for project 1."
            link={Project1Link}
          />
        </Fragment>
      }
    />
  )
}

export default Work
